import React, { useEffect } from 'react';
import './App.css';
import Header from '../components/header/Header';
import Services from '../components/services/Services';
import WhyChooseUs from '../components/whyChooseUs/WhyChooseUs';
import GetInTouch from '../components/getInTouch/GetInTouch';
import Footer from '../components/footer/Footer';

function App() {
  useEffect(() => {
    const parallaxContainer = document.querySelector('.parallax-container') as HTMLElement;
    if (parallaxContainer) {
        parallaxContainer.addEventListener('scroll', function () {
            const scrollTop = parallaxContainer.scrollTop;
            const parallaxSections = document.querySelectorAll('.parallax-section');
            parallaxSections.forEach(section => {
                const speed = parseFloat(section.getAttribute('data-speed') || '1');
                (section as HTMLElement).style.transform = `translateY(-${scrollTop * speed}px)`;
            });
        });
    }
  }, []);


  return (
    <div className="App">
      <Header />
      <main>
        <Services />
        <WhyChooseUs />
        <GetInTouch />
      </main>
      <Footer />
    </div>
  );
}

export default App;
