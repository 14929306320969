import React from 'react';
import './SocialMediaLinks.css';
import Facebook from '../../assets/img/icons8-facebook-48.png';
import X from '../../assets/img/icons8-twitterx-50.png';
import Instagram from '../../assets/img/icons8-instagram-48.png';
import Tiktok from '../../assets/img/icons8-tiktok-50.png';
import Youtube from '../../assets/img/icons8-youtube-48.png';
import Github from '../../assets/img/icons8-github-48.png';

function SocialMediaLinks() {
  return (
    <div className="social-media-links">
      <h3>Follow Us</h3>
      <ul>
        <li><a href="https://twitter.com/TridimTech"><img alt='X' src={X} /></a></li>
        <li><a href="https://www.tiktok.com/@tridim.technologies"><img alt='Titok' src={Tiktok} /></a></li>
        <li><a href="https://www.youtube.com/channel/UCdYZMwkpPaLQxg9OTIv3EDw"><img alt='Youtube' src={Youtube} /></a></li>
        <li><a href="https://www.instagram.com/tridimtechnologies"><img alt='Instagram' src={Instagram} /></a></li>
        <li><a href="https://www.facebook.com/profile.php?id=100068650190948"><img alt='Facebook' src={Facebook} /></a></li>
        <li><a href="https://github.com/TridimTech"><img style={{height: 31, width: 31, marginBottom: 5}} alt='Github' src={Github} /></a></li>
      </ul>
    </div>
  );
}

export default SocialMediaLinks;
