import React from 'react';
import './Services.css';

function Services() {
  return (
    <div className="grid-container" style={{backgroundColor:' #f8f9fa'}}>
      <div>
        <section className="services">
          <h2 style={{textAlign: 'center'}}>Our Services</h2>
          <p>
              <p><strong>Web Development:</strong> From sleek, responsive websites to complex web applications, we specialize in crafting digital experiences that engage and delight users across all devices and platforms.</p>
              <p><strong>Mobile App Development:</strong> Our expertise in mobile app development spans iOS, Android, and cross-platform solutions. We work closely with you to create intuitive and feature-rich apps that stand out in the crowded app marketplace.</p>
              <p><strong>Gaming Solutions:</strong> With a passion for gaming and years of experience in the industry, we develop captivating and immersive gaming experiences for various platforms, including PC, console, and mobile.</p>
              <p><strong>Desktop Applications:</strong> Whether you need a standalone desktop application or an enterprise-grade software solution, our team has the skills and expertise to turn your vision into reality.</p>
          </p>
        </section>
      </div>
    </div>
  );
}

export default Services;
