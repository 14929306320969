// components/Hero.tsx
import React from 'react';
import { Parallax } from 'react-parallax';
import heroImage from '../../assets/img/hero_background.png';
import './Hero.css';

function Hero() {
  return (
    <Parallax
      blur={{ min: -15, max: 15 }}
      bgImageAlt="TRIDIM TECHNOLOGIES"
      bgImage={heroImage} 
      strength={-200}>
      <div className="hero">
        <h1 style={{textAlign: 'center'}}>Welcome to Tridim Technologies</h1>
        <p>
            At Tridim Technologies, we are passionate about leveraging cutting-edge technology to build innovative solutions for web, mobile, gaming, and desktop platforms.
            Our dedicated team of developers and designers work tirelessly to bring your ideas to life, delivering high-quality products that exceed your expectations.
        </p>
      </div>
      <div style={{ height: '200px' }} />
    </Parallax>
  );
}

export default Hero;
