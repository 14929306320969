// ContactForm.tsx
import React from 'react';
import './ContactForm.css';

function ContactForm() {
  return (
    <div className="grid-container">
      <div>
          <div className="contact-form">
          <h2 style={{textAlign: 'center'}}>Contact Us</h2>
          <form>
            <input type="text" placeholder="Your Name" />
            <input type="email" placeholder="Your Email" />
            <textarea placeholder="Your Message"></textarea>
            <button type="submit">Send Message</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
