// components/GetInTouch.tsx
import React from 'react';
import ContactForm from '../contact/ContactForm';
import SocialMediaLinks from '../social/SocialMediaLinks';
import './GetInTouch.css';

function GetInTouch() {
  return (
    <section className="get-in-touch">
      <ContactForm />
      <SocialMediaLinks />
    </section>
  );
}

export default GetInTouch;
