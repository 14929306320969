import React from 'react';
import './NavBar.css';
import logo_header from '../../assets/img/logo_header.png';

function Navbar() {
  return (
    <nav>
      <img alt='' src={logo_header} className='center' />
      <h4 style={{marginTop: -20, marginBottom: 2}}>TRIDIM</h4>
    </nav>
  );
}

export default Navbar;
