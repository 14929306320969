// components/Header.tsx
import React from 'react';
import Navbar from '../nav/NavBar';
import Hero from '../hero/Hero';
import './Header.css';

function Header() {
  return (
    <header>
      <Navbar />
      <Hero />
    </header>
  );
}

export default Header;
