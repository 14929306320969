import React from 'react';
import './WhyChooseUs.css';

function WhyChooseUs() {
  return (

    <div className="grid-container">
      <div>
        <section className="why-choose-us">
          <h2 style={{textAlign: 'center'}}>Why Choose Us</h2>
          <p className='container'>
              <p><strong>Expertise:</strong> Our team comprises seasoned professionals with expertise in a wide range of technologies and platforms.</p>
              <p><strong>Innovation:</strong> We stay ahead of the curve by embracing emerging technologies and trends, ensuring that your products are always at the forefront of innovation.</p>
              <p><strong>Collaboration:</strong> We believe in open communication and collaboration throughout the development process, keeping you involved every step of the way.</p>
              <p><strong>Quality Assurance:</strong> Our rigorous quality assurance processes guarantee that your products meet the highest standards of quality and performance.</p>
          </p>
        </section>
      </div>
    </div>
  );
}

export default WhyChooseUs;

